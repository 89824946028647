<template>
  <el-card>
    <el-form
      class="search-input"
      :model="listQuery"
      :rules="rules"
      label-width="80px"
    >
      <el-row>
        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
          <el-form-item prop="appName" label="应用名称">
            <el-input
              class="sub-input"
              v-model="listQuery.appName"
              placeholder="请输入应用名称"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
          <el-form-item prop="package" label="应用包名">
            <el-input
              class="sub-input"
              v-model="listQuery.package"
              placeholder="请输入应用包名"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
          <el-row>
            <el-form-item label="下载量">
              <el-col :span="11">
                <el-form-item prop="downloadMin">
                  <el-input
                    class="sub-input"
                    v-model.number="listQuery.downloadMin"
                    placeholder="请输入下限"
                    type="number"
                    @input="checkInt('downloadMin')"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="2">-</el-col>
              <el-col :span="11">
                <el-form-item prop="downloadMax">
                  <el-input
                    class="sub-input"
                    v-model.number="listQuery.downloadMax"
                    placeholder="请输入上限"
                    type="number"
                    @input="checkInt('downloadMax')"
                  />
                </el-form-item>
              </el-col>
            </el-form-item>
          </el-row>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
          <el-row>
            <el-form-item label="大小">
              <el-col :span="11">
                <el-form-item prop="sizeMin">
                  <el-input
                    class="sub-input"
                    v-model.number="listQuery.sizeMin"
                    placeholder="请输入下限"
                    type="number"
                    @input="checkInt('sizeMin')"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="2">-</el-col>
              <el-col :span="11">
                <el-form-item prop="sizeMax">
                  <el-input
                    class="sub-input"
                    v-model.number="listQuery.sizeMax"
                    placeholder="请输入上限"
                    type="number"
                    @input="checkInt('sizeMax')"
                  />
                </el-form-item>
              </el-col>
            </el-form-item>
          </el-row>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
          <el-form-item prop="developer" label="开发商">
            <el-input
              class="sub-input"
              v-model="listQuery.developer"
              placeholder="请输入开发商"
            />
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
          <el-form-item prop="point" label="评分">
            <el-input
              class="sub-input"
              v-model.number="listQuery.point"
              placeholder="请输入评分（大于等于）"
              type="number"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
          <el-row>
            <el-form-item label="评分数">
              <el-col :span="11">
                <el-form-item prop="pointNumMin">
                  <el-input
                    class="sub-input"
                    v-model.number="listQuery.pointNumMin"
                    placeholder="请输入下限"
                    type="number"
                    @input="checkInt('pointNumMin')"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="2" style="text-align: center">-</el-col>
              <el-col :span="11">
                <el-form-item prop="pointNumMax">
                  <el-input
                    class="sub-input"
                    v-model.number="listQuery.pointNumMax"
                    placeholder="请输入上限"
                    type="number"
                    @input="checkInt('pointNumMax')"
                  />
                </el-form-item>
              </el-col>
            </el-form-item>
          </el-row>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
          <el-form-item prop="category" label="类别">
            <el-select
              class="sub-input"
              v-model="listQuery.category"
              placeholder="请选择选择类别"
            >
              <el-option
                v-for="item in categoryList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
          <el-form-item prop="status" label="状态">
            <el-select
              class="sub-input"
              v-model="listQuery.status"
              placeholder="请选择状态"
            >
              <el-option
                label="上架"
                :value="enumValue.appStatusOnline"
              ></el-option>
              <el-option
                label="下架"
                :value="enumValue.appStatusOffline"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
          <el-form-item prop="isCollection" label="是否收藏">
            <el-select
              class="sub-input"
              v-model="listQuery.isCollection"
              placeholder="请选择是否收藏"
            >
              <el-option label="是" :value="enumValue.isCollection"></el-option>
              <el-option
                label="否"
                :value="enumValue.notCollection"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
          <el-form-item prop="isRecommend" label="是否推荐">
            <el-select
              class="sub-input"
              v-model="listQuery.isRecommend"
              placeholder="请选择是否推荐"
            >
              <el-option label="是" :value="enumValue.isRecommend"></el-option>
              <el-option label="否" :value="enumValue.notRecommend"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
          <el-row>
            <el-form-item label="更新时间">
              <el-col :span="11">
                <el-form-item prop="updateStart">
                  <el-date-picker
                    class="sub-input"
                    v-model="listQuery.updateStart"
                    type="date"
                    placeholder="开始时间"
                    value-format="YYYY-MM-DD"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="2">-</el-col>
              <el-col :span="11">
                <el-form-item prop="updateEnd">
                  <el-date-picker
                    class="sub-input"
                    v-model="listQuery.updateEnd"
                    align="right"
                    type="date"
                    placeholder="结束时间"
                    :picker-options="pickerOptions1"
                    value-format="YYYY-MM-DD"
                  />
                </el-form-item>
              </el-col>
            </el-form-item>
          </el-row>
        </el-col>

        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
          <el-form-item prop="manufacturer" label="厂商">
            <el-input
              class="sub-input"
              v-model="listQuery.manufacturer"
              placeholder="请输入厂商名"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
          <el-row>
            <el-col :span="8">
              <el-sapn>
                <el-tag>只显示中国</el-tag>
                <el-switch
                  v-model="switchStatus"
                  active-color="#13ce66"
                  @change="switchChange"
                />
              </el-sapn>
            </el-col>
            <el-col :span="7">
              <el-form-item>
                <el-button @click="searchList">搜索</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="2"> </el-col>
            <el-col :span="7">
              <el-form-item>
                <el-button @click="resetValue">重置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
  <el-card>
    <el-table
      :data="tableData"
      style="width: 100%"
      max-height="590px"
      :key="itemKey"
      :default-sort="defaultSort"
      @sort-change="sortChange"
      v-loading="loading"
      @row-click="goToGP"
    >
      <el-table-column prop="appIcon" label="AppIcon" width="90">
        <template v-slot="scoped">
          <el-image
            style="width: 40px; height: 40px"
            :src="scoped.row.appIcon"
            fit="contain"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="应用名称"
        width="320"
        :show-overflow-tooltip="false"
      />
      <el-table-column prop="category" label="类别" width="100" />
      <el-table-column
        prop="lastUpdate"
        label="更新日期"
        width="120"
        sortable="custom"
      >
        <template v-slot="{ row }">
          {{ dayjs.unix(row.lastUpdate).format("YYYY-MM-DD") }}
        </template>
      </el-table-column>
      <el-table-column
        prop="download"
        label="下载量"
        width="120"
        :formatter="downloadFormat"
        sortable="custom"
      />
      <el-table-column prop="size" label="大小" width="120" sortable="custom" />
      <el-table-column prop="rate" label="评分" width="100" sortable="custom" />
      <el-table-column
        prop="totalRate"
        label="评分数"
        width="100"
        sortable="custom"
      />
      <el-table-column prop="country" label="国家" width="80" />
      <el-table-column prop="developer" label="开发商" width="240" />
      <el-table-column
        prop="manufacturerName"
        label="厂商"
        width="220"
        :show-overflow-tooltip="false"
      >
      </el-table-column>
      <el-table-column
        prop="isCollection"
        label="收藏"
        width="90"
        fixed="right"
      >
        <template v-slot="{ row }">
          <el-button type="text" size="small" @click="operateCollection(row)">
            {{ collectionShow(row.isCollection) }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <el-card :body-style="{ padding: '2px' }">
    <el-pagination
      v-model:currentPage="listQuery.page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="listQuery.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageTotal"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </el-card>
</template>

<script>
import { addCollection, cancelCollection, getAppList } from "../api/app";
import enumValue from "../utils/enum";
import * as dayjs from "dayjs";
const defaultPage = 1;
const defaultSize = 100;
const defaultListQuery = {
  appName: "",
  package: "",
  downloadMin: undefined,
  downloadMax: undefined,
  sizeMin: undefined,
  sizeMax: undefined,
  developer: "",
  point: undefined,
  pointNumMin: undefined,
  pointNumMax: undefined,
  category: "",
  status: enumValue.appStatusOnline,
  isCollection: undefined,
  isRecommend: undefined,
  updateStart: "",
  updateEnd: "",
  manufacturer: "",
  page: defaultPage,
  pageSize: defaultSize,
  sort: enumValue.SortDesc,
  by: enumValue.appListByLastUpdate,
  country: "",
};
export default {
  data() {
    return {
      dayjs,
      loading: false,
      switchStatus: false,
      tableData: [],
      filters: {
        statusFilter(status) {
          const statusMap = {
            online: 1,
            offline: 2,
          };
          return statusMap[status];
        },
      },
      categoryList: [
        { value: "Action" },
        { value: "Adventure" },
        { value: "Arcade" },
        { value: "Board" },
        { value: "Card" },
        { value: "Casino" },
        { value: "Casual" },
        { value: "Educational" },
        { value: "Music" },
        { value: "Puzzle" },
        { value: "Racing" },
        { value: "Role Playing" },
        { value: "Simulation" },
        { value: "Sports" },
        { value: "Strategy" },
        { value: "Trivia" },
        { value: "Word" },
      ],
      enumValue,
      defaultSort: { prop: "lastUpdate", order: "descending" },
      pageTotal: 100,
      listQuery: {
        appName: "",
        package: "",
        downloadMin: undefined,
        downloadMax: undefined,
        sizeMin: undefined,
        sizeMax: undefined,
        developer: "",
        point: undefined,
        pointNumMin: undefined,
        pointNumMax: undefined,
        category: "",
        status: enumValue.appStatusOnline,
        isCollection: undefined,
        isRecommend: undefined,
        updateStart: "",
        updateEnd: "",
        manufacturer: "",
        page: defaultPage,
        pageSize: defaultSize,
        sort: enumValue.SortDesc,
        by: enumValue.appListByLastUpdate,
        country: "",
      },
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },

      itemKey: "",
    };
  },
  created() {
    const listQuery = this.$route.query.listQuery;
    if (listQuery) {
      this.listQuery = JSON.parse(listQuery);
    }
    this.getList();
  },
  watch: {
    $route(to, from) {
      //监听相同路由下参数变化的时候，从而实现异步刷新
      //重新获取数据
      if (to.name == from.name) {
        const listQuery = this.$route.query.listQuery;
        if (listQuery) {
          this.listQuery = JSON.parse(listQuery);
        } else {
          this.listQuery = defaultListQuery;
        }
        this.getList();
      }
    },
  },
  methods: {
    // 获取列表数据
    getList() {
      this.loading = true;
      getAppList(this.listQuery)
        .then((resp) => {
          this.tableData = resp.data.data.records;
          this.pageTotal = resp.data.data.total;
          // this.itemKey = Math.random();
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 搜索
    searchList() {
      this.listQuery.page = defaultPage;
      this.$router.push({
        name: "appList",
        query: { listQuery: JSON.stringify(this.listQuery) },
      });
    },
    // 显示收藏
    collectionShow(collection) {
      if (collection == "1") {
        return "已收藏";
      }
      return "未收藏";
    },
    // 收藏操作
    async operateCollection(row) {
      if (row.isCollection == "0") {
        await addCollection({ app_id: row.id });
        row.isCollection = "1";
      } else {
        cancelCollection({ app_id: row.id });
        row.isCollection = "0";
      }
    },
    // 页面条数变化
    handleSizeChange(size) {
      this.listQuery.pageSize = size;
      this.$router.push({
        name: "appList",
        query: { listQuery: JSON.stringify(this.listQuery) },
      });
    },
    // 翻页
    handleCurrentChange(page) {
      this.listQuery.page = page;
      this.$router.push({
        name: "appList",
        query: { listQuery: JSON.stringify(this.listQuery) },
      });
    },
    // 跳转gp
    goToGP(row) {
      const gpLink =
        "https://play.google.com/store/apps/details?id=" +
        row.packageName +
        "&hl=en";
      window.open(gpLink, "_blank");
    },
    // 时间格式化
    dataFormat(timestamp) {
      return dayjs.unix(timestamp).format("YYYY-MM-DD");
    },
    checkInt(bindVar) {
      this[`${bindVar}`] = this[`${bindVar}`].replace(/[^0-9]/g, "");
    },
    resetValue() {
      this.listQuery.isRecommend = undefined;
      this.listQuery.appName = "";
      this.listQuery.package = "";
      this.listQuery.downloadMin = undefined;
      this.listQuery.downloadMax = undefined;
      this.listQuery.sizeMin = undefined;
      this.listQuery.sizeMax = undefined;
      this.listQuery.developer = "";
      this.listQuery.point = undefined;
      this.listQuery.pointNumMin = undefined;
      this.listQuery.pointNumMax = undefined;
      this.listQuery.category = "";
      this.listQuery.status = enumValue.appStatusOnline;
      this.listQuery.isCollection = undefined;
      this.listQuery.isRecommend = undefined;
      this.listQuery.updateStart = "";
      this.listQuery.updateEnd = "";
      this.listQuery.manufacturer = "";
    },
    getCategoryShow(status) {
      const categoryMap = {
        0: "success",
        1: "info",
      };
      return categoryMap[status];
    },
    sortChange(obj) {
      var sortByMap = {
        lastUpdate: "last_update",
        download: "download",
        size: "size",
        rate: "rate",
        totalRate: "total_rate",
      };
      if (obj.order == "ascending") {
        this.listQuery.sort = "asc";
      } else if (obj.order == "descending") {
        this.listQuery.sort = "desc";
      } else {
        this.listQuery.sort = "";
      }
      if (obj.prop) {
        this.listQuery.by = sortByMap[obj.prop];
      } else {
        this.listQuery.by = "";
      }
      this.getList();
    },
    // 国家切换
    switchChange() {
      if (this.switchStatus == true) {
        this.listQuery.country = "CN";
      } else {
        this.listQuery.country = "";
      }
      this.$router.push({
        name: "appList",
        query: { listQuery: JSON.stringify(this.listQuery) },
      });
    },
    // 下载量格式化
    downloadFormat(row, column, cellValue) {
      cellValue += "";
      if (!cellValue.includes(".")) cellValue += ".";
      return cellValue
        .replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
          return $1 + ",";
        })
        .replace(/\.$/, "");
    },
  },
};
</script>

<style scoped>
label {
  padding-right: 10px;
}

.sub-input {
  width: 100%;
}

.el-form-item {
  padding: 0;
  margin: 1px;
}
.el-form .el-row .el-col {
  padding: 0;
  margin: 0;
}

.el-card {
  margin: 2px;
  padding: 0;
}
a:link {
  color: rgb(36, 35, 35);
  text-decoration: none;
}
.search-input {
  font-size: 10px;
}
</style>